<template>
  <div>
    <el-container style="height: 100%">
      <el-aside width="auto" >
        <!-- commonAside组件 -->
				<commonAside />
			</el-aside>
      <el-container>
        <el-header>
          <!-- Header组件 -->
          <CommonHeader />
        </el-header>
        <el-main>
          <!-- Tag组件 -->
          <CommonTabs style="margin:-10px 0 10px 0" />
          <!-- 内容显示区域 -->
          <Keep-alive>
            <router-view ref="routerView" :key="$route.fullPath"></router-view>
          </Keep-alive>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import CommonAside from '../components/CommonAside.vue'
import CommonHeader from '../components/CommonHeader.vue'
import CommonTabs from '../components/CommonTabs.vue'
export default {
  name: "Home",
  data() {
    return {};
  },
	components:{
		CommonAside,CommonHeader,CommonTabs
	},
  watch: {
    $route(to, from) {
      let arr = JSON.parse(localStorage.getItem('tabsList'))
      if (arr.indexOf(to.name) < 0 && from.name) {
      //  this.$router.go(0); // 刷新该页面
      const This = this;
      const vueInstance = this.$refs.routerView;
      const caches = vueInstance.$vnode.parent.componentInstance.cache;
      Object.keys(caches).map((key) => {
        // if (!This.delBeforeViews.find((item) => item.path == key)) {
          This.removeCache(caches[key].componentInstance);
        // }
      })
      }

    }
  },
  methods:{
    removeCache(vueInstance) {
      const key =
        vueInstance.$vnode.key ??
        vueInstance.$vnode.componentInstance.Ctor.cid +
          (vueInstance.$vnode.componentInstance.tag
            ? `::${vueInstance.$vnode.componentInstance.tag}`
            : "");
      const cache = vueInstance.$vnode.parent.componentInstance.cache;
      const keys = vueInstance.$vnode.parent.componentInstance.keys;
      if (cache[key]) {
        vueInstance.$destroy();
        delete cache[key];
        const index = keys.indexOf(key);
        if (index > -1) {
          keys.splice(index, 1);
        }
      }
    }
  }
};
</script>

<style>
.el-header {
	background-color: #333;
}
.el-aside {
	background-color: #ddd;
}
.el-main {
	background-color: #fff;
}
</style>