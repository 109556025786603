<template>
  <el-menu
    :default-active="active"
    class="el-menu-vertical-demo"
    @open="handleOpen"
    @close="handleClose"
    :collapse="isCollapse"
    background-color="#545c64"
    text-color="#fff"
    active-text-color="#ffd04b"
  >
    <!-- 控制标题变化， 如果isCollapse为true收起状态只显示后天两字 -->
    <h3 class="logo">{{ isCollapse ? "门店" : "门店小程序管理后台" }}</h3>
    <!-- 遍历无子项菜单-->
    <div v-for="(item, index) in menu" :key="index">
			<el-menu-item
				v-if="!item.children || item.children.length == 0"
				@click="clickMenu(item)"
				:index="item.path"
			>
				<!-- :class="'el-icon-'+item.icon" 字符串拼接获取不同的icon -->
				<i :class="'el-icon-' + item.icon"></i>
				<span slot="title">{{ item.label }}</span>
			</el-menu-item>
			<!-- 遍历有子项菜单 -->
			<el-submenu v-if="item.children && item.children.length > 0" :index="item.code">
				<template slot="title">
					<i :class="'el-icon-' + item.icon"></i>
					<span slot="title">{{ item.label }}</span>
				</template>
				<!-- 遍历二级菜单 -->
				<el-menu-item-group v-for="subItem in item.children" :key="subItem.path">
					<el-menu-item v-if="!subItem.is_hidden" @click="clickMenu(subItem)" :index="subItem.path">{{
						subItem.label
					}}</el-menu-item>
				</el-menu-item-group>
			</el-submenu>
		</div>
  </el-menu>
</template>

<script>
import Cookie from 'js-cookie'
export default {
  name: "CommonAside",
  data() {
    return {
      active: "/Home",
      menu: [],
    };
  },
  mounted() {
		this.menu = JSON.parse(localStorage.getItem('menu'))	
		this.menu = this.createObj(this.menu, [])
	},
  watch: {
    $route: {
      handler: function (val, old) {
        if (val.fullPath === "/Home") {
          this.active = "/Home";
        } else {
          this.active = val.fullPath;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    createObj(data, arr) {
		  for (let item in data) {

				if (data[item].is_hidden) {
					continue
				}
		      arr.push({
        	  code: data[item].code,
        	  is_hidden: data[item].is_hidden,
        	  label: data[item].label,
        	  sort: data[item].sort,
        	  name: data[item].name,
        	  path: data[item].path,
        	  status: data[item].status,
        	  icon: data[item].icon,
        	  type: data[item].type,
        	  children: []
        	});
					if (data[item].children && data[item].children.length > 0) {
		    	  this.createObj(
		    	    data[item].children,
		    	    arr[arr.length -1 ].children
		    	  );
		    	}
		  }
		  return arr;
		},
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
    // 点击导航实现路由跳转
    clickMenu(item) {
      if (!Cookie.get('token')) {
        location.reload([true])
        return 
      }
      this.$router.push({
        name: item.name,
      });
      // this.$router.replace({
      //   path: '/redirect' + item.path
      // })
      // this.$store.commit('tab/selectMenu', item) //item为对应menu数据
      this.$store.commit("selectMenu", item); //item为对应menu数据
    },
  },
  computed: {
    // 过滤数据中有子项和没有子项的数据
    noChildren() {
      return this.asyncMenu.filter((item) => !item.children);
    },
    hasChildren() {
      return this.asyncMenu.filter((item) => item.children);
    },
    // 拿到Vuex中共享的isCollapse数据
    isCollapse() {
      return this.$store.state.tab.isCollapse;
      // 过滤数据中有子项和没有子项的数据
    },
    asyncMenu() {
      // return this.$store.state.tab.menu
      return this.menu;
    },
  },
};
</script>

<style lang="less" scoped>
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 400px;
}
.el-menu {
  height: 100%;
  border: none;
  // 文字不换行
  white-space: nowrap;
  h3 {
    color: #fff;
    text-align: center;
    line-height: 48px;
  }
}
</style>